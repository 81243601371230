import React from "react"

export default function IconGPlay() {
  return (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.87 15.698 4.69 4.048a2.651 2.651 0 0 1 1.838.312l16.21 8.273-3.868 3.065ZM4.066 27.417A1.28 1.28 0 0 1 4 27.069V4.736a.767.767 0 0 1 .035-.1L18.172 16.25 4.066 27.417Zm14.796-10.6 3.33 2.736-15.663 8a3.035 3.035 0 0 1-1.79.44l14.123-11.176Zm4.73-3.745 3.631 1.854c1.036.569 1.036 1.488 0 2.06l-4.183 2.136-3.479-2.858 4.032-3.192Z"
        fill="#C1C9D2"
      />
    </svg>
  )
}
