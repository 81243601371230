import React from "react"

export const IconTg = () => (
  <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity=".1" cx="16" cy="16" r="16" fill="#A1AFBF" />
    <path
      d="m8.882 14.918 12.455-4.802c.578-.209 1.082.14.895 1.015l.001-.001-2.12 9.99c-.157.708-.578.88-1.167.547l-3.23-2.38-1.557 1.5c-.172.173-.318.318-.651.318l.23-3.286 5.984-5.407c.26-.23-.058-.359-.402-.13l-7.396 4.656-3.188-.994c-.692-.22-.707-.693.146-1.026Z"
      fill="#A1AFBF"
    />
  </svg>
)
