import React from "react"

export const IconOk = () => (
  <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity=".1" cx="16" cy="16" r="16" fill="#A1AFBF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.982 12.311c0 1.933-1.64 3.503-3.65 3.503s-3.65-1.57-3.65-3.503c0-1.94 1.64-3.511 3.65-3.511s3.65 1.57 3.65 3.511Zm-1.857 0c0-.958-.806-1.732-1.793-1.732-.988 0-1.793.774-1.793 1.732 0 .95.806 1.725 1.793 1.725.987 0 1.793-.775 1.793-1.725Zm-5.032 5.527c-.917-.568-1.32-.901-.965-1.597.208-.395.779-.729 1.535-.154 0 0 1.021.782 2.67.782 1.648 0 2.67-.782 2.67-.782.757-.572 1.325-.241 1.536.154.349.696-.053 1.028-.97 1.6-.77.471-1.832.658-2.532.728l1.528 1.465 1.207 1.159c.786.774-.492 1.984-1.285 1.236-.529-.516-1.3-1.258-2.107-2.035l-.04-.038-2.148 2.07c-.793.745-2.07-.476-1.277-1.236l2.727-2.624c-.005.005-.099.096 0 0-.692-.07-1.77-.246-2.55-.728Z"
      fill="#A1AFBF"
    />
  </svg>
)
