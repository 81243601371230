import React from "react"

export default function IconAppstore() {
  return (
    <svg width="30" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.491 14.805c0 2.221 1.375 4.23 3.497 5.108l.012.223s-1.778 5.356-5.172 6.635c-1.9.717-3.452-.466-3.736-.584a4.34 4.34 0 0 0-4.088.286c-2.703 1.536-4.758-.895-4.758-.895a14.492 14.492 0 0 1-2.112-2.72 13.87 13.87 0 0 1-2.11-6.879 9.454 9.454 0 0 1 .913-4.762A7.082 7.082 0 0 1 4.974 8.14a6.516 6.516 0 0 1 2.67-.667 9.013 9.013 0 0 1 3.769.958 2.008 2.008 0 0 0 1.504.103 16.184 16.184 0 0 1 3.135-1.016 6.99 6.99 0 0 1 3.89.335 4.705 4.705 0 0 1 2.466 2.126c-1.803.989-2.916 2.83-2.917 4.826Zm-7.408-7.697S11.996 1.645 17.614 1c-.022 6.353-5.53 6.108-5.53 6.108Z"
        fill="#C1C9D2"
      />
    </svg>
  )
}
