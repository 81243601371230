import React from "react"

export default function IconRustore() {
  return (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.508 28.3c-5.566 0-8.35 0-10.079-1.73C3.7 24.842 3.7 22.059 3.7 16.493v-.984c0-5.566 0-8.35 1.73-10.079C7.158 3.7 9.941 3.7 15.507 3.7h.984c5.566 0 8.35 0 10.079 1.73 1.73 1.728 1.73 4.512 1.73 10.078v.984c0 5.566 0 8.35-1.73 10.079-1.73 1.729-4.513 1.729-10.079 1.729h-.984Z"
        fill="#C1C9D2"
      />
      <path
        d="M17.925 18.86a1.485 1.485 0 0 1-1.127-1.438V9.41c0-.773.73-1.34 1.483-1.151l4.767 1.19a1.484 1.484 0 0 1 1.127 1.44v8.01c0 .773-.73 1.34-1.482 1.151l-4.768-1.19ZM8.952 22.55a1.484 1.484 0 0 1-1.127-1.439V13.1c0-.773.73-1.34 1.482-1.151l4.768 1.19a1.485 1.485 0 0 1 1.127 1.44v8.01a1.19 1.19 0 0 1-1.482 1.151l-4.768-1.19Z"
        fill="#fff"
      />
      <path
        d="M13.439 20.705a1.484 1.484 0 0 1-1.127-1.439v-8.01c0-.773.73-1.34 1.482-1.152l4.767 1.191a1.484 1.484 0 0 1 1.128 1.439v8.01c0 .773-.73 1.34-1.483 1.152l-4.767-1.191Z"
        fill="#fff"
      />
      <path
        d="M16.56 21.486a.484.484 0 0 1-.365-.453l-.208-6.118c-.06-.81-.66-1.453-1.289-1.643a.085.085 0 0 0-.094.034.072.072 0 0 0 .017.096c.155.12.582.512.582 1.188l-.002 6.552 1.358.344ZM21.047 19.64a.482.482 0 0 1-.365-.451l-.207-6.118c-.06-.81-.662-1.453-1.29-1.643a.085.085 0 0 0-.093.034.072.072 0 0 0 .016.096c.155.12.582.512.582 1.188l-.001 6.555 1.358.34Z"
        fill="#C1C9D2"
      />
    </svg>
  )
}
