import React from "react"

import IconAppstore from "./icons/IconAppstore"
import IconGPlay from "./icons/IconGPlay"
import IconAppGallery from "./icons/IconAppGallery"
import IconRustore from "./icons/IconRustore"

import * as styles from "./storeLinks.module.scss"

const items = [
  {
    rel: "nofollow",
    href: "https://scb.im/iosapp",
    Icon: IconAppstore,
  },
  {
    rel: "",
    href: "https://scb.im/androidapp",
    Icon: IconGPlay,
  },
  {
    rel: "nofollow",
    href: "https://hva.im/D2fFvH",
    Icon: IconAppGallery,
  },
  {
    rel: "nofollow",
    href: "https://apps.rustore.ru/app/ru.sovcomcard.halva.v1",
    Icon: IconRustore,
  },
]

export function StoreLinks() {
  return (
    <div className={styles.store}>
      {items.map(({ rel, href, Icon }, i) => (
        <a
          key={i}
          href={href}
          target="_blank"
          rel={`noopener noreferrer ${rel}`}
          className={styles.link}
        >
          <Icon />
        </a>
      ))}
    </div>
  )
}
