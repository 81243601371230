import React from "react"

export const IconVk = () => (
  <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity=".1" cx="16" cy="16" r="16" fill="#A1AFBF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.28 18.442c.365.373 1.16 1 1.015 1.637-.133.585-1.006.371-1.854.405-.968.041-1.541.063-2.124-.405-.274-.222-.435-.485-.698-.779-.239-.265-.54-.742-.95-.723-.736.036-.505 1.062-.767 1.762-4.092.644-5.735-1.884-7.185-4.337C9.015 14.814 8 12.262 8 12.262l2.894-.01s.928 1.688 1.174 2.124c.21.37.44.664.677.994.2.275.514.812.86.768.56-.072.662-2.249.314-2.978-.138-.295-.47-.398-.814-.498.116-.732 3.246-.885 3.752-.317.735.825-.509 3.123.498 3.793 1.413-.74 2.62-3.841 2.62-3.841l3.388.021s-.53 1.676-1.085 2.42c-.324.435-1.398 1.404-1.355 2.123.033.57.907 1.124 1.356 1.581Z"
      fill="#A1AFBF"
    />
  </svg>
)
