import React from "react"

import { IconOk } from "./icons/IconOk"
import { IconTg } from "./icons/IconTg"
import { IconVk } from "./icons/IconVk"

import * as styles from "./socialLinks.module.scss"

const items = [
  {
    rel: "nofollow",
    href: "https://vk.com/sovcombank",
    Icon: IconVk,
  },
  {
    rel: "nofollow",
    href: "https://t.me/sovcombankofficial",
    Icon: IconTg,
  },
  {
    rel: "nofollow",
    href: "https://ok.ru/paosovcombank",
    Icon: IconOk,
  },
]

export function SocialLinks() {
  return (
    <div className={styles.links}>
      {items.map(({ rel, href, Icon }, i) => (
        <a
          key={i}
          href={href}
          target="_blank"
          rel={`noopener noreferrer ${rel}`}
          className={styles.link}
        >
          <Icon />
        </a>
      ))}
    </div>
  )
}
