import React from "react"

export default function IconAppGallery() {
  return (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.83 4H9.17A5.17 5.17 0 0 0 4 9.17v13.66A5.17 5.17 0 0 0 9.17 28h13.66A5.17 5.17 0 0 0 28 22.83V9.17A5.17 5.17 0 0 0 22.83 4Z"
        fill="#C1C9D2"
      />
      <path
        d="M9.85 5.568a.684.684 0 0 0-.682.681.68.68 0 0 0 .683.68.684.684 0 0 0 .683-.68v-.002a.68.68 0 0 0-.684-.679Zm12.213 0a.684.684 0 0 0-.682.681.679.679 0 0 0 .683.68.684.684 0 0 0 .682-.68v-.002a.68.68 0 0 0-.683-.679Z"
        fill="#fff"
        fillOpacity=".2"
      />
      <path
        d="M9.823 6.155c-.238.013-.226.358-.226.358 1.276 2.483 3.624 3.701 6.331 3.701 2.705-.004 5.076-1.235 6.35-3.715 0 0 .05-.467-.447-.305-1.306 2.165-3.5 3.445-5.901 3.45-2.405 0-4.592-1.267-5.9-3.436a.433.433 0 0 0-.207-.053Zm8.63 9.653-.725 2.16-.707-2.159h-.689l1.113 3.141h.537l.727-2.063.726 2.063h.541l1.111-3.14h-.672l-.708 2.158-.725-2.16h-.529Zm-3.853 0-1.408 3.14h.662l.272-.607.019-.046h1.462l.287.654h.68l-1.395-3.122-.013-.018H14.6Zm10.194.001v3.138h.64v-3.138h-.64Zm-18.342 0v3.142h.649v-1.276h1.463v1.276h.649V15.81h-.649v1.268H7.101V15.81h-.649Zm5.762.001v1.799c0 .51-.258.783-.726.783-.471 0-.731-.28-.731-.805v-1.775h-.648v1.797c0 .884.5 1.39 1.37 1.39.88 0 1.383-.516 1.383-1.416V15.81h-.648Zm9.396.001v3.138h2.413v-.572h-1.774V17.6h1.18v-.572h-1.18v-.643h1.712v-.572H21.61Zm-6.736.793.463 1.052.031.072h-.98l.03-.072.456-1.052Z"
        fill="#fff"
      />
    </svg>
  )
}
